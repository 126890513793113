// COMMON CSS
/*---- Session (Sign in, sign up, forgot, lockscreen) -----*/
.page-wrap {
  display: flex;
  align-items: center;
  padding: 0;
  height: 100%;
  background: #3d4866;
  position: fixed;
  width: 100%;
}

.session-progress {
  position: relative;
  bottom: -4px;
  z-index: 9999;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: none !important;
}

.session-form-hold {
  width: 100%;
  max-width: 40rem;
  flex: 1 1 32%;
  z-index: 1;
}

.container-wrap {
  width: 100%;
  height: calc(100vh - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  padding: 0;
}

@media screen and (max-width:1420px) and (min-width:720px) {
  .session-form-hold {
    max-width: 38rem !important;
  }

  .page-wrap {
    padding: 1rem 1rem 0rem 1rem !important;
  }
}

@media screen and (min-width:2200px) {
  .container-wrap {
    width: 92% !important;
  }

  .session-form-hold {
    width: 100%;
    max-width: 48rem !important;
    flex: 1 1 50% !important;
  }
  
  .signInImg {
    height: 100vh;
    
    & img {
      width: 100%;
      max-height: none;
    }
  }

  .session-form-hold>.mat-card {
    margin-left: -5rem !important;
  }

  .page-wrap {
    padding: 4rem 1rem 0rem 1rem !important;
  }
}

.session-form-hold>.mat-card {
  margin: 0;
  margin-left: 0;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
}

@media screen and (min-width:921px) {
  .session-form-hold>.mat-card {
    margin-left: -2rem;
  }
}

.container-card {
  height: 97vh;
  width: 100%;
  display: flex;
  padding: 0rem 4rem;
  align-items: flex-start;
  justify-content: flex-end;
  background: url('../../../../images/signIn.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.session-lockscreen {
  max-width: 320px;
}

.lockscreen-user {
  text-align: center;
  margin-bottom: 1rem;
  display: flow-root;
  overflow: hidden;
}

.lockscreen-face {
  max-width: 72px;
  max-height: 72px;
  border-radius: 50%;
  margin-bottom: .5rem;
}

.signInImg {
  flex: 1 1 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 20px);
  overflow: hidden;
  padding: 10px;
  background-color: transparent;

  & img {
    width: 95%;
    height: 95%;
    max-height: none;
    object-fit: contain;
    border-radius: 0;
    box-shadow: none;
  }
}

@media screen and (max-width:520px) {
  .session-form-hold {
    max-width: 100% !important;
  }
  
  .session-form-hold>.mat-card {
    margin-left: 0 !important;
  }
  
  .page-wrap {
    padding: 1rem 0.5rem 0rem 0.5rem !important;
  }
  
  .signInImg {
    height: calc(100vh - 40px);
  }
}

@media screen and (max-width:1200px) and (min-width:921px) {
  .signInImg {
    height: calc(100vh - 20px);
    
    & img {
      width: 100%;
      max-height: none;
      object-fit: contain;
    }
  }
}

@media screen and (min-width:1421px) {
  .signInImg {
    height: calc(100vh - 20px);
    
    & img {
      width: 100%;
      max-height: none;
      object-fit: contain;
    }
  }
}

.signin-image {
  transition: all 0.3s ease;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}